import {createResponseInterceptor} from "../axios.service";
import {AxiosRequestConfig} from "axios";
import {SecurityService} from "../../rxjs/epics/security/security.service";

declare module 'axios' {
    export interface AxiosRequestConfig {
        _retry?: boolean;
    }
}

export const TokensResponseInterceptor = createResponseInterceptor(
    async function (response) {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        //TODO: add 403 and other similar errors, not only 401
        if (error.response?.status === 401 && originalRequest && !originalRequest?._retry) {
                originalRequest._retry = true;
                await SecurityService.updateAccessToken();

                // const access_token = await SecurityService.updateAccessToken();
                // this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                return this.axiosInstance(originalRequest);
        } else {
            throw error;
        }
    }
);