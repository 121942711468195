import React, {PropsWithChildren} from "react";
import {useLocation} from "react-router";
import LayoutStyles from "./ankored-layout.module.scss";
import {LeftPanel} from "./panels/left-panel";
import {HeaderPanel} from "./panels/header-panel";

const SecurityFlowLayout = ({children}: PropsWithChildren) => {
    return (
        <div className={LayoutStyles.body}>
            {children}
        </div>
    )
}

const DefaultLayout = ({children}: PropsWithChildren) => {
    return (
        <div className={LayoutStyles.body}>
            <div className={LayoutStyles.defaultLayoutWrapper}>
                <LeftPanel/>
                <div className={LayoutStyles.contentWrapper}>
                    <HeaderPanel />
                    {children}
                </div>
            </div>
        </div>
    )
}

interface IAnkoredLayout {
    isAuthorized: boolean
}

export const AnkoredLayout = ({isAuthorized, ...props}: PropsWithChildren<IAnkoredLayout>) => {
    //const {pathname} = useLocation();

    switch (isAuthorized) {
        case false: return <SecurityFlowLayout {...props} />
        case true: return <DefaultLayout {...props} />
        default: return <DefaultLayout {...props} />;
    }
}
