import React from "react";
import LoginStyles from "./login.module.scss";
import {LeftSideComponent} from "./components/left-side";
import {RightSideComponent} from "./components/right-side";

export const SignInScreen = () => {
    return (
        <>
            <div className={LoginStyles.leftside}>
                <LeftSideComponent />
            </div>
            <div className={LoginStyles.rightSide}>
                <RightSideComponent />
            </div>
        </>
    )
}