export const initialPaginationInput: IPaginationInput = {
    size: 10,
    from: 0
}

export type TPagination = {
    total: number,
    size: number,
    from: number
}

export const initialPagination: TPagination = {
    total: 0,
    size: 10,
    from: 0
}

export interface IPaginationInput {
    size: number;
    from: number;
}