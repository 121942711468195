import LayoutStyles from "../ankored-layout.module.scss";
import React, {useEffect} from "react";
import {ReactComponent as LogoLeftSide} from "../../../../assets/Logo2.svg";
import {useDispatch, useSelector} from "react-redux";
import {OrganizationsRootState} from "../../../../state-management/states/organizations/organizations.state";
import {IItemState} from "../../../../types/item-state";
import {organizationLastUpdatesLoadAction} from "../../../../rxjs/actions/organizations.action";

export const LeftPanel = () => {
    //TODO: upd any based on generated interfaces
    const {data: lastUpdates} =
        useSelector<OrganizationsRootState, IItemState<any, any>>(({organizations}) => organizations.lastUpdates
        );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(organizationLastUpdatesLoadAction());
    }, []);

    return (
        <div className={LayoutStyles.leftPanelWrapper}>
            <LogoLeftSide className={LayoutStyles.logo}/>
            <div className={LayoutStyles.infoBlock}>
                <span className={LayoutStyles.title}>Last 30 days:</span>

                <div className={LayoutStyles.item}>
                    <span>Total New Accounts: </span>
                    <span>{lastUpdates.totalNewAccounts}</span>
                </div>

                <div className={LayoutStyles.item}>
                    <span>Total Lost Accounts: </span>
                    <span>{lastUpdates.totalLostAccounts}</span>
                </div>

                <div className={LayoutStyles.item}>
                    <span>Total New Users: </span>
                    <span>{lastUpdates.totalNewUsers}</span>
                </div>

                <div className={LayoutStyles.item}>
                    <span>Total New Teams: </span>
                    <span>{lastUpdates.totalNewTeams}</span>
                </div>

                <div className={LayoutStyles.item}>
                    <span>Total Number of Background Checks Completed: </span>
                    <span>{lastUpdates.totalNewBcc}</span>
                </div>

                <div className={LayoutStyles.item}>
                    <span>Total Number of Abuse Prevention Training Completed: </span>
                    <span>{lastUpdates.totalNewApt}</span>
                </div>
            </div>
        </div>
    )
}