// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-cell_container__-fNCB {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 8px 0 8px 0;\n}\n\n.table-cell_right__oX19l {\n  justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/table/table-cell/table-cell.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EAEA,oBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":[".container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n\r\n  padding: 8px 0 8px 0;\r\n}\r\n\r\n.right {\r\n  justify-content: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "table-cell_container__-fNCB",
	"right": "table-cell_right__oX19l"
};
export default ___CSS_LOADER_EXPORT___;
