import React, {FC, useMemo} from 'react';
import {TextInput, TextInputProps} from "../text-input/text-input";
import {isString} from "../../utils/util-fns";
import {usePasswordInput} from "./password-input-hook";
import {withFormik} from "../formik/hocs/hoc-formik";

const PasswordInputComponent: FC<TextInputProps> = ({ value, placeholder = 'Password', showClearButton = true, ...props }) => {
    const hasClearButton = useMemo(() => showClearButton && isString(value), [showClearButton, value])
    const { isShowPassword, inputType } = usePasswordInput(hasClearButton);

    //TODO: add show password icon and attach functional
    return (
            <TextInput
                // inputStyle={PasswordInputStyles.input}
                value={value}
                type={inputType}
                placeholder={placeholder}
                showClearButton={showClearButton}
                {...props}
            />
    );
};
export const PasswordInput = React.memo(PasswordInputComponent) as typeof PasswordInputComponent;
export const FormikPasswordInput = withFormik(PasswordInput);
