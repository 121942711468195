import {useField} from 'formik';
import React, {FC, useCallback, useMemo} from 'react';
import {ErrorMessage} from "../components/error/error";
import {
    FromFormikFn,
    GetInputPropsFieldType,
    InputProps,
    MessageComponentProps,
    ToFormikFn,
    WithFormikHocProps
} from "../types/types";

export const emptyComponentFn = () => null;
export const defaultFromFormik = <InputType, FormikType>(i: FormikType): InputType => (i as unknown) as InputType;
export const defaultToFormik = <InputType, FormikType>(i: InputType): FormikType => (i as unknown) as FormikType;
export const withFormik = <Props extends InputProps<InputType>, InputType = GetInputPropsFieldType<Props>, FormikType = string>
(
    Component: FC<Props>,
    initialFromFormikFn: FromFormikFn<InputType, FormikType> = defaultFromFormik,
    initialToFormikFn: ToFormikFn<InputType, FormikType> = defaultToFormik,
    ErrorMessageComponent: FC<MessageComponentProps> = ErrorMessage
) =>
    React.memo<FC<WithFormikHocProps<Props, InputType, FormikType>>>(
        (props: WithFormikHocProps<Props, InputType, FormikType>) => {
            const {
                name,
                MessageComponent = emptyComponentFn,
                onChange = (...args: any[]) => void 0,
                fromFormikFn = initialFromFormikFn,
                toFormikFn = initialToFormikFn,
                ...inputProps
            } = props;
            const [{value, onBlur}, {error, touched}, {setValue}] = useField<FormikType>(name);
            const memoizedValue = useMemo(() => fromFormikFn(value), [fromFormikFn, value]);

            const hasError = useMemo(() => touched && !!error, [touched, error]);
            const hasMessageComponent = MessageComponent !== emptyComponentFn && !hasError;

            const memoizedMessage = value?.toString() ?? '';
            const memoizedError = hasError ? error : '';

            const handleChange = useCallback(
                (newValue: InputType) => {
                    setValue(toFormikFn(newValue));
                    onChange(newValue);
                },
                [name, onChange]
            );

            //TODO: add error message component
            return (
                <>
                    {/* @ts-ignore */}
                    <Component
                        name={name}
                        value={memoizedValue}
                        hasError={hasError}
                        onBlur={onBlur}
                        onChange={handleChange}
                        {...(inputProps as Omit<Props, 'value' | 'onChange'>)}
                    />
                    {hasMessageComponent ? (
                        <MessageComponent message={memoizedMessage} {...(props as Props)} />
                    ) : (
                        <ErrorMessageComponent message={memoizedError}/>
                    )}
                </>
            );
        }
    );
