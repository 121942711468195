import {TableCell, TableRow} from "../../../../ankored-components/table";
import React from "react";
import {DashboardClientsTableEnum} from "./dashboard-clients-table";
import DashboardClientsTableRowStyles from "./dashboard-clients-table-row.module.scss";

const getStatus = (isActive: boolean, isDeleted: boolean): string => {
    if(!!isDeleted){
        return "Deleted"
    } else if(!!isActive){
        return "Active"
    } else {
        return "Inactive"
    }
}

//TODO: upd any based on generated interfaces
//TODO: ask about logos, clientStatus and what to do with long org names
export const DashboardClientsTableRow = ({name, clientStatus, creationTime, admins, users, teams, isActive, isDeleted}: any) => {
    return (
        <TableRow>
            <TableCell name={DashboardClientsTableEnum.ClientName} className={DashboardClientsTableRowStyles.clientCellStyle}>
                <span className={DashboardClientsTableRowStyles.title}>{name}</span>
                <span className={DashboardClientsTableRowStyles.clientStatus}>{clientStatus}</span>
            </TableCell>
            <TableCell name={DashboardClientsTableEnum.DateCreated}>
                <span className={DashboardClientsTableRowStyles.boldItem}>{new Date(creationTime).toLocaleDateString("en-US")}</span>
            </TableCell>
            <TableCell name={DashboardClientsTableEnum.Admins}>
                <span className={DashboardClientsTableRowStyles.defaultItem}>{admins}</span>
            </TableCell>
            <TableCell name={DashboardClientsTableEnum.Users}>
                <span className={DashboardClientsTableRowStyles.defaultItem}>{users}</span>
            </TableCell>
            <TableCell name={DashboardClientsTableEnum.Teams}>
                <span className={DashboardClientsTableRowStyles.boldItem}>{teams}</span>
            </TableCell>
            <TableCell name={DashboardClientsTableEnum.Status}>
                <span className={DashboardClientsTableRowStyles.boldItem}>{getStatus(isActive, isDeleted)}</span>
            </TableCell>
        </TableRow>
    )
}