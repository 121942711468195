import {ITableCellStyleConfig, TTableConfig} from "../type/table-config.type";
import TableStyles from "../table-styles.module.scss"

const defaultTableCellStyleConfig: ITableCellStyleConfig = {
    className: TableStyles.cell,
    first: false,
    last: false,
    numeric: false
};

export const useTableCellStyleConfigByName = (configs: TTableConfig, name: string): ITableCellStyleConfig => ({
    ...defaultTableCellStyleConfig,
    ...configs.find(config => config.name === name)
});