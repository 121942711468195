import { DependencyList, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {ActionCreator, Typed} from "ts-action";

import {IPaginationInput, OnEventFn, TPagination} from "../../../types";
import {emptyFn} from "../../../utils/util-fns";

export type ListLoadHandler = [PaginationChangeFnType];

export type PaginationChangeFnType = (pagination?: IPaginationInput) => void;

export type TsActionCreator<T> = ActionCreator<string, (payload: T) => Typed<{ payload: T }, string>>;

//TODO: check why on a first render it sends 2 requests
export const useTableLoad = (
    listLoadAction: TsActionCreator<IPaginationInput>,
    queryVariables: IPaginationInput,
    deps: DependencyList = [],
    pagination?: TPagination,
    onLoad: OnEventFn<IPaginationInput> = emptyFn
): ListLoadHandler => {
    const dispatch = useDispatch();

    const loadList = (newQueryVariables: IPaginationInput) => {
        const validQueryVariables = {
            ...newQueryVariables,
            from: Math.max(0, newQueryVariables.from)
        };

        onLoad(validQueryVariables);
        dispatch(listLoadAction(validQueryVariables));
    };

    const loadFirstPageOnWrongPagination = () => {
        if (pagination !== undefined) {
            pagination?.from > pagination?.total &&
            pagination?.total !== 0 &&
            loadList({
                ...queryVariables,
                from: 1
            });
        }
    };

    const onPaginationChange: PaginationChangeFnType = (newPaginationData = queryVariables) =>
        loadList(newPaginationData);

    useEffect(loadFirstPageOnWrongPagination, [pagination, queryVariables]);
    useEffect(onPaginationChange, deps);

    return [onPaginationChange];
};
