// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".separator_wrapper__gaR4G {\n  display: flex;\n  align-items: center;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n.separator_wrapper__gaR4G .separator_separator__OOloQ {\n  flex-grow: 1;\n  border-top: 1px solid gray;\n}\n.separator_wrapper__gaR4G .separator_content__A9LHO {\n  flex-shrink: 1;\n  margin-left: 6px;\n  margin-right: 6px;\n  color: gray;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/separator/separator.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AACF;AACE;EACE,YAAA;EACA,0BAAA;AACJ;AAEE;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AAAJ","sourcesContent":[".wrapper {\r\n  display: flex;\r\n  align-items: center;\r\n  padding-top: 10px;\r\n  padding-bottom: 10px;\r\n\r\n  .separator {\r\n    flex-grow: 1;\r\n    border-top: 1px solid gray;\r\n  }\r\n\r\n  .content {\r\n    flex-shrink: 1;\r\n    margin-left: 6px;\r\n    margin-right: 6px;\r\n    color: gray;\r\n\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "separator_wrapper__gaR4G",
	"separator": "separator_separator__OOloQ",
	"content": "separator_content__A9LHO"
};
export default ___CSS_LOADER_EXPORT___;
