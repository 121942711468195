import React, {CSSProperties, PropsWithChildren} from 'react';
import TableRowStyles from "./table-row.module.scss";
import classNames from "classnames";

interface Props {
    hasDivider?: boolean
    style?: CSSProperties
}

export const TableRow = ({hasDivider = true, children, style}: PropsWithChildren<Props>) => {
    return (
        <div
            style={style}
            className={classNames(TableRowStyles.root, hasDivider && TableRowStyles.divider)}
        >
            {children}
        </div>
    )
}

