import {reducer} from "ts-action";
import {
    organizationsAggregatedDataLoadReducer,
    organizationsLastUpdatesLoadReducer,
    organizationsListLoadReducer
} from "./organizations-load.reducer";
import {organizationsInitialState} from "../../states/organizations/organizations.initial.state";

export const organizationsReducer = reducer(
    organizationsInitialState,
    ...organizationsListLoadReducer,
    ...organizationsAggregatedDataLoadReducer,
    ...organizationsLastUpdatesLoadReducer
);
