import {action, payload} from "ts-action";
import {ISignIn} from "../../types";

export interface ITokenPayload {
    access_token: string;
    refresh_token: string;
}

export interface ITokenPayloadExtended extends ITokenPayload , Pick<ISignIn, "remember">{}

export interface ISignInResponse extends ITokenPayload{
    expires_in: number;
    token_type: string;
    scope: string;
}

export const securityAuthorizedAction = action('[Security] Authorized');

export const securityAccessTokenRefreshSuccessAction = action('[Security] Access token refresh success', payload<string>());

export const securitySignInSuccessAction = action('[Security] Successful sign in', payload<ITokenPayloadExtended>());
export const securitySignInFailedAction = action('[Security] Sign in failed');
export const securitySignInAction = action('[Security] Sign in', payload<ISignIn>());