import React from "react";
import {AggregatedData} from "./components/accommodated-info/aggregated-data";
import DashboardStyles from "./dashboard.module.scss"
import {DashboardClientsTable} from "./components/dashboard-clients-table/dashboard-clients-table";

export const DashboardScreen = () => {
    return (
        <div className={DashboardStyles.dashboardWrapper}>
            <AggregatedData />
            <DashboardClientsTable />
        </div>
    );
}