import React, {CSSProperties, HTMLInputTypeAttribute, useCallback} from 'react';
import {withFormik} from "../formik/hocs/hoc-formik";
import {InputProps} from "../formik/types/types"
import {emptyFn, isDefined, isEmptyString} from "../../utils/util-fns";
import TextInputStyles from "./text-input.module.scss";

const inputChangeValidation = (value: string, changedValue: string, pattern: RegExp) => {
    const last = changedValue.substring(changedValue.length - 1, changedValue.length);
    const diffIndex = value.split('').findIndex((char, index) => char !== changedValue.charAt(index));

    const testLast = pattern.test(last);
    const testInside = pattern.test(changedValue.charAt(diffIndex));

    return testLast && (diffIndex < 0 || testInside);
};

const handleValidation = (text: string, pattern?: RegExp): boolean =>
    pattern === undefined || isEmptyString(text) ? true : pattern.test(text);

export interface TextInputProps<InputType = string>
    extends InputProps<InputType> {
    inputStyle?: CSSProperties;
    label?: string;
    pattern?: RegExp;
    placeholder?: string;
    showClearButton?: boolean;
    type?: HTMLInputTypeAttribute;
}

const TextInputComponent = React.forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            name,
            type = 'text',
            value,
            placeholder,
            label,
            style,
            inputStyle,
            pattern,
            onChange = emptyFn,
        },
        ref
    ) => {
        const handleChange = useCallback(
            (text: string) => {
                const prevValue = String(value);
                const changedValue = String(text);
                const isHandleDelete = prevValue.length > changedValue.length;
                !isHandleDelete && isDefined(pattern)
                    ? inputChangeValidation(prevValue, changedValue, pattern) && onChange(text)
                    : handleValidation(text, pattern) && onChange(text);
            },
            [onChange, pattern]
        );

        return (
            <>
                {
                    !!label && (
                        <label htmlFor={name} className={TextInputStyles.label}>{label}</label>
                    )
                }
                <div style={style} className={TextInputStyles.wrapper}>
                    <input
                        ref={ref}
                        value={value ?? ""}
                        placeholder={placeholder}
                        name={name}
                        type={type}
                        onChange={event => handleChange(event.target.value)}
                        className={TextInputStyles.input}
                        style={inputStyle}
                    />
                </div>
            </>
        );
    }
);

export const TextInput = React.memo(TextInputComponent) as typeof TextInputComponent;
export const FormikTextInput = withFormik(TextInput);
