import { Reducer } from 'redux';
import { on, reducer } from 'ts-action';

import { rootStateResetAction } from './root-state.action';

export const rootStateReducer = <T>(appReducer: Reducer<T>): Reducer<T> => (appState, action) => {
    const rootReducer = reducer(
        appState,
        on(rootStateResetAction, _ => undefined)
    );

    const state = rootReducer(appState, action);

    return appReducer(state, action);
};
