import React from "react";
import LoginStyles from "../login.module.scss";
import {ReactComponent as BackgroundRightSideFirstAsset} from "../../../assets/background-svg1.svg";
import LogoRightSide from "../../../assets/Logo1.png";
import {ReactComponent as BackgroundRightSideSecondAsset} from "../../../assets/background-svg2.svg";

export const RightSideComponent = () => {
    return (
        <>
            <BackgroundRightSideFirstAsset className={LoginStyles.asset1}/>
            <img src={LogoRightSide}/>
            <h2 className={LoginStyles.title}>
                All documents <br /> Ankored in one place
            </h2>
            <span>
                    All important data and information <br /> completely digital.
                </span>
            <BackgroundRightSideSecondAsset className={LoginStyles.asset2}/>
        </>
    )
}