import React, {useLayoutEffect, useState} from 'react';
import redux from '../../state-management/ankored-configure-store';
import {AnkoredRoot} from "./ankored-root/ankored-root";
import {Root} from "./root/root";
import {BrowserHistory} from "history";
import {BrowserRouterProps, Router} from "react-router-dom";
import customHistory from "./history";

interface Props extends BrowserRouterProps {
    history: BrowserHistory;
}

//TODO: check unstable_HistoryRouter instead of using custom
const CustomRouter = ({basename, history, children}: Props) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });
    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            navigator={customHistory}
            location={state.location}
            navigationType={state.action}
            children={children}
            basename={basename}
        />
    );
};

export const AnkoredApp = () => (
    <CustomRouter history={customHistory}>
        <Root {...redux}>
            <AnkoredRoot/>
        </Root>
    </CustomRouter>
);
