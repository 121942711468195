// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_link__TjovE {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  letter-spacing: 0;\n  font-weight: 600;\n  color: #475270;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/link/link.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".link {\r\n  font-size: 1rem;\r\n  line-height: 1.5rem;\r\n  letter-spacing: 0;\r\n  font-weight: 600;\r\n  color: #475270;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_link__TjovE"
};
export default ___CSS_LOADER_EXPORT___;
