import {combineEpics} from "redux-observable";
import {ofType, toPayload} from 'ts-action-operators';
import {Action} from "ts-action";
import {concatMap, defer, EMPTY, from, iif, Observable, switchMap} from "rxjs";
import {applicationInitAction} from "../../actions/application.action";
import {
    securityAuthorizedAction,
    securitySignInAction,
    securitySignInFailedAction,
    securitySignInSuccessAction
} from "../../actions/security.action";
import {SecurityRootState} from "../../../state-management/states/security/security.state";
import {clearJWTTokens, getJWTTokens, setJWTTokens} from "../../../utils/jwt-storage.util";
import {SecurityService} from "./security.service";

function isAuthenticated(accessToken: string | null, refreshToken: string | null) {
    try {
        //TODO: upd
        //jwt.decode(token);
        //const { exp } = jwt.decode(refreshToken);
        // if (Date.now() >= exp * 1000) {
        //     return false;
        // }
        return !!refreshToken;
    } catch (err) {
        return false;
    }

    return true;
}

//Epic to handle application reload
const securityApplicationReloadEpic = (action$: Observable<Action>, state$: Observable<SecurityRootState>) =>
    action$.pipe(
        ofType(applicationInitAction),
        concatMap(() => from(getJWTTokens())),
        // filter((tokens): tokens is [string, string] => tokens.every(token => token !== null)),
        concatMap(([access_token, refresh_token]) =>
            iif(
                () => isAuthenticated(access_token, refresh_token),
                defer(() => [
                    securityAuthorizedAction(),
                    // securitySignInSuccessAction({
                    //     //TODO: find out what to do here with null type...
                    //     access_token: access_token ?? "",
                    //     refresh_token: refresh_token ?? "",
                    //     remember: false
                    // })
                ]),
                defer(() => [securitySignInFailedAction()])
            ))
    );

//Epic to handle sign in by login screen action
const securitySignInEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(securitySignInAction),
        toPayload(),
        switchMap( ({email, remember, password}) => SecurityService.login({email, remember, password}))
    );

//Epic to handle successful sign in and set tokens to local storage, if needed
const securitySignInSuccessEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(securitySignInSuccessAction),
        toPayload(),
        switchMap(({access_token, refresh_token, remember}) => iif(
            () => remember,
            defer(() => setJWTTokens({access_token, refresh_token})),
            EMPTY
        )),
        // switchMap(({access_token, refresh_token, remember}) => iif(
        //     () => remember,
        //     defer(() => from(setJWTTokens({access_token, refresh_token})).pipe(
        //         map(() => ({access_token, refresh_token}))
        //     )),
        //     defer(() => of({access_token, refresh_token}))
        // )),
        // concatMap(({access_token}) => {
        //     //const user = JSON.parse(new Buffer(accessToken.split('.')[1], 'base64').toString());
        //     return [
        //         //TODO: actions to load additional info
        //         securityAuthorizedAction()
        //     ];
        // })
    );

//Epic to handle failed sign in and delete tokens from local storage
const securitySignInFailedEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(securitySignInFailedAction),
        concatMap(() => {
            clearJWTTokens();
            return EMPTY;
        })
    );

//Epic where defined further actions after all auth checks
const securityAuthorizedEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(securityAuthorizedAction),
        //TODO: place for the array of general actions like loadUser, loadOrganization etc.
        concatMap(() => EMPTY)
    )

export const securityEpic =
    combineEpics(
        securityApplicationReloadEpic,
        securitySignInEpic,
        //TODO: find out how to fix it
        // @ts-ignore
        securitySignInSuccessEpic,
        securitySignInFailedEpic,
        securityAuthorizedEpic
    );