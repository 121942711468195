// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_wrapper__696F\\+ {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 48px;\n  border-radius: 8px;\n  color: #E7ECF2;\n  background-color: #475270;\n  cursor: pointer;\n  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/button/button.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,yBAAA;EACA,eAAA;EACA,+CAAA;AACF","sourcesContent":[".wrapper {\r\n  display: flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 100%;\r\n  height: 48px;\r\n  border-radius: 8px;\r\n  color: #E7ECF2;\r\n  background-color: #475270;\r\n  cursor: pointer;\r\n  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);\r\n}\r\n\r\n.text {\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "button_wrapper__696F+"
};
export default ___CSS_LOADER_EXPORT___;
