export const isDefined = <T>(value: T | null | undefined): value is T => {
    return value !== undefined && value !== null;
};

export const isString = (str: any): str is string => isDefined(str) && typeof str === 'string' && str.length !== 0;

export const isEmptyString = (str: any): boolean => {
    return !isDefined(str) || (typeof str === 'string' && str.length === 0);
};

export type EmptyFn = (...args: any[]) => void;
export const emptyFn: EmptyFn = () => void 0;

type TQueryObject = Record<string, string | number | boolean>;

export const getQueryParamsFromObj = (queryObject: TQueryObject): string => {
    if (!queryObject) {
        return "";
    }

    const queryObjectEntries = Object.entries(queryObject);

    if (!queryObjectEntries.length) {
        return "";
    }

    const filteredQueryObjectEntries = queryObjectEntries.filter(
        ([, queryParamValue]) => !(queryParamValue === null || queryParamValue === undefined)
    );

    if (!filteredQueryObjectEntries.length) {
        return "";
    }

    const queryParametersArray = filteredQueryObjectEntries.map(
        ([queryParamKey, queryParamValue]) => `${queryParamKey}=${encodeURIComponent(queryParamValue)}`
    );

    return `?${queryParametersArray.join("&")}`;
};