// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-input_label__5yUWb {\n  display: block;\n  padding-bottom: 8px;\n  padding-top: 16px;\n}\n\n.text-input_input__sL3tI {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/text-input/text-input.module.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,mBAAA;EACA,iBAAA;AAHF;;AAMA;EACE,WAAA;AAHF","sourcesContent":[".wrapper {\r\n\r\n}\r\n\r\n.label {\r\n  display: block;\r\n  padding-bottom: 8px;\r\n  padding-top: 16px;\r\n}\r\n\r\n.input {\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "text-input_label__5yUWb",
	"input": "text-input_input__sL3tI"
};
export default ___CSS_LOADER_EXPORT___;
