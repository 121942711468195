import React from "react";
import {
    TableConfigContext,
    TableHeaderCell,
    TableHeaderRow,
    TablePagination,
    TTableConfig
} from "../../../../ankored-components";
import TableStyles from "../../../../ankored-components/table/table-styles.module.scss";
import {DashboardClientsTableRow} from "./dashboard-clients-table-row";
import {useSelector} from "react-redux";
import {OrganizationsRootState} from "../../../../state-management/states/organizations/organizations.state";
import {IPaginationInput} from "../../../../types";
import {IListState} from "../../../../types/list-state";
import {useTableLoad} from "../../../../ankored-components/table/hook/use-table-load.hook";
import {organizationListLoadAction} from "../../../../rxjs/actions/organizations.action";
import {LoaderSpinner} from "../../../../ankored-components/loader/loader";
import DashboardTableStyles from "./dashboard-clients-table.module.scss";

export enum DashboardClientsTableEnum {
    ClientName = 'Client Name',
    DateCreated = 'Date Created',
    Admins = 'Admins',
    Users = 'Users',
    Teams = 'Teams',
    Status = 'Status'
}

export const DashboardClientsTable = () => {
    //TODO: upd any based on generated interfaces
    const {data: organizations, pagination, queryVariables, isLoading} =
        useSelector<OrganizationsRootState, IListState<any, IPaginationInput>>(({organizations}) => organizations.list
        );

    const [handlePaginationChange] = useTableLoad(organizationListLoadAction, queryVariables);

    const ClientsTableConfig: TTableConfig = [
        { name: DashboardClientsTableEnum.ClientName, className: TableStyles.lCell, first: true },
        { name: DashboardClientsTableEnum.DateCreated, className: TableStyles.lCell },
        { name: DashboardClientsTableEnum.Admins, className: TableStyles.lCell },
        { name: DashboardClientsTableEnum.Users, className: TableStyles.lCell },
        { name: DashboardClientsTableEnum.Teams, className: TableStyles.lCell },
        { name: DashboardClientsTableEnum.Status, className: TableStyles.lCell, last: true },
    ];

    //TODO: make a table wrapper component with functional for loader and placeholder within
    return (
            <TableConfigContext.Provider value={ClientsTableConfig}>
                <TablePagination onPaginationChange={handlePaginationChange} pagination={{...queryVariables, ...pagination}} />
                <TableHeaderRow hasDivider={false}>
                    <TableHeaderCell name={DashboardClientsTableEnum.ClientName}>Client Name</TableHeaderCell>
                    <TableHeaderCell name={DashboardClientsTableEnum.DateCreated}>Date Created</TableHeaderCell>
                    <TableHeaderCell name={DashboardClientsTableEnum.Admins}>Admins</TableHeaderCell>
                    <TableHeaderCell name={DashboardClientsTableEnum.Users}>Users</TableHeaderCell>
                    <TableHeaderCell name={DashboardClientsTableEnum.Teams}>Teams</TableHeaderCell>
                    <TableHeaderCell name={DashboardClientsTableEnum.Status}>Status</TableHeaderCell>
                </TableHeaderRow>
                {
                    isLoading ? (
                        <div className={DashboardTableStyles.loaderWrapper}>
                            <LoaderSpinner />
                        </div>
                    ) : (
                        organizations.map(organization => <DashboardClientsTableRow {...organization} key={organization.id}/>)
                    )
                }
            </TableConfigContext.Provider>
    );
}