import React, {FC} from 'react';
import {initialPagination, IPaginationInput, OnEventFn, TPagination} from "../../../types";
import {emptyFn} from "../../../utils/util-fns";
import PaginationStyles from "./table-pagination-styles.module.scss";
import classNames from "classnames";

interface Props {
    onPaginationChange?: OnEventFn<IPaginationInput>;
    pagination?: TPagination;
}

export const TablePagination: FC<Props> =
    ({
         pagination: {total, size, from} = initialPagination,
         onPaginationChange = emptyFn
    }) => {
    const isPaginationAvailable = total > 1;

    const isDisabledPrevButton = from === 0;
    const isDisabledNextButton = (from + size) >= total;

    const handleLoadPrevPage = () => onPaginationChange({
        size,
        from: from - size

    });
    const handleLoadNextPage = () => onPaginationChange({
        size,
        from: from + size
    });

    return isPaginationAvailable ? (
        <div className={PaginationStyles.paginationWrapper}>
            <span
                className={classNames(isDisabledPrevButton && PaginationStyles.disabled, PaginationStyles.previous)}
                {...(!isDisabledPrevButton && {onClick: handleLoadPrevPage})}
            >
                Previous
            </span>
            <span
                className={classNames(isDisabledNextButton && PaginationStyles.disabled)}
                {...(!isDisabledNextButton && {onClick: handleLoadNextPage})}
            >
                Next
            </span>
        </div>
    ) : null;
};
