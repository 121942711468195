import React from "react";
import SeparatorStyles from "./separator.module.scss";

interface Props {
    content?: any
}

export const Separator = ({content}: Props) => {
    return (
        <div className={SeparatorStyles.wrapper}>
            <div className={SeparatorStyles.separator}></div>
            {content && <span className={SeparatorStyles.content}>{content}</span>}
            <div className={SeparatorStyles.separator}></div>
        </div>
    )
}