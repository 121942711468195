import {useTableConfig} from "./use-table-config.hook";
import {useTableCellStyleConfigByName} from "./use-table-cell-config.hook";
import {getTableCellClassName} from "../util/table.util";


export const useTableCellStyle = (name: string, initialNumeric = false) => {
    const cellStyleConfig = useTableCellStyleConfigByName(useTableConfig(), name);

    return {
        cellStyle: cellStyleConfig.style,
        className: getTableCellClassName(cellStyleConfig),
        numeric: cellStyleConfig.numeric ?? initialNumeric
    };
};
