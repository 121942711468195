import {on, On} from "ts-action";
import {SecurityState} from "../../states/security/security.state";
import {
    securityAccessTokenRefreshSuccessAction,
    securitySignInFailedAction,
    securitySignInSuccessAction
} from "../../../rxjs/actions/security.action";

export const authReducer: Array<On<SecurityState>> = [
    on(securitySignInSuccessAction, (state, { payload: auth }) => ({
        ...state,
        auth,
        isLoggedIn: true
    })),
    on(securitySignInFailedAction, (state, {}) => ({
        ...state,
        auth: null,
        isLoggedIn: false
    })),
    on(securityAccessTokenRefreshSuccessAction, (state, { payload: access_token }) => ({
        ...state,
        auth: {
            ...state.auth,
            access_token
        },
        isLoggedIn: true
    }))
];