import React, {CSSProperties, PropsWithChildren, useCallback, useMemo} from 'react';
import {emptyFn, isDefined} from "../../utils/util-fns";
import {withFormik} from "../formik/hocs/hoc-formik";
import {InputProps} from "../formik/types/types";
import CheckBoxInputStyles from "./checkbox.module.scss";

export interface CheckboxInputProps extends InputProps<boolean> {
    label?: string,
    itemsPosition?: "row" | "row-reverse" | "column" | "column-reverse",
    checkBoxStyle?: CSSProperties
}

const CheckBoxInputComponent = React.forwardRef<HTMLInputElement, CheckboxInputProps & PropsWithChildren>(
    (
        {
            value,
            name,
            label,
            itemsPosition = "row",
            onChange = emptyFn,
            checkBoxStyle,
            style,
            children
        },
        ref
    ) => {

        const handleClick = useCallback(() => onChange(!value), [onChange, value]);

        const isLabelExist = useMemo(() => {
            return !isDefined(children) && isDefined(label)
        }, [children, label]);

        const wrapperStyles: CSSProperties = {
            flexDirection: itemsPosition,
            ...style
        }

        return (
            <div
                className={CheckBoxInputStyles.wrapper}
                style={wrapperStyles}
            >
                <div
                    onClick={handleClick}
                    className={CheckBoxInputStyles.group}
                >
                    <input
                        type="checkbox"
                        ref={ref}
                        checked={value}
                        name={name}
                    />
                    <svg
                        className={`${CheckBoxInputStyles.checkbox} ${value ? CheckBoxInputStyles.checkboxActive : ""}`}
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M5 12.5 L 10 17 L19 8"
                            strokeWidth="2"
                            strokeLinejoin="round"
                            stroke={value ? "#fff" : "none"} // only show the checkmark when `isCheck` is `true`
                        />
                    </svg>
                    {
                        isLabelExist && <label htmlFor={name} className={CheckBoxInputStyles.label}>{label}</label>
                    }
                </div>
                {children}
            </div>
        )
    }
)

export const CheckboxInput = React.memo(CheckBoxInputComponent) as typeof CheckBoxInputComponent;
export const FormikCheckboxInput = withFormik(CheckboxInput);
