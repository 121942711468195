import React, {PropsWithChildren} from "react";
import {HttpService} from "./http.service";


export const HttpServiceContext = React.createContext<HttpService>(HttpService.instance);
HttpServiceContext.displayName = "HttpServiceContext";

export const HttpServiceProvider = (props: PropsWithChildren) => {

    const instance = HttpService.instance;

    return (
        <HttpServiceContext.Provider value={instance}>{props.children}</HttpServiceContext.Provider>
    );
};

//TODO: add hook to use http service from context