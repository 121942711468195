import React, {CSSProperties, PropsWithChildren} from 'react';
import TableHeaderRowStyles from "./table-header-row.module.scss";
import classNames from "classnames";

interface Props {
    style?: CSSProperties;
    hasDivider?: boolean;
}

export const TableHeaderRow= ({ hasDivider = true, style, children }: PropsWithChildren<Props>) => {
    return (
        <div
            style={style}
            className={classNames(TableHeaderRowStyles.root, hasDivider && TableHeaderRowStyles.rootDivider)}
        >
            {children}
        </div>
    );
}
