import {createRequestInterceptor} from "../axios.service";

export const TokensRequestInterceptor = createRequestInterceptor(
    async function (request) {
        const {security: {auth}} = this.store.getState();
        const access_token = auth?.access_token;

        //TODO: check if this headers is enough
        request.headers = {
            ...(!!access_token && {Authorization: `Bearer ${access_token}`}),
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        return request;
    },
    async function (error) {
            throw error;
    }
);