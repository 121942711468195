import {initialPagination, initialPaginationInput, IPaginationInput} from "../../types";
import {IListState} from "../../types/list-state";

export const listQueryVariablesInitialState: IPaginationInput = {
    ...initialPaginationInput
};

// tslint:disable-next-line:no-any
export const listInitialState: IListState<any, IPaginationInput> = {
    data: [],
    queryVariables: listQueryVariablesInitialState,
    pagination: initialPagination,
    isLoading: false,
    error: ''
};