// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard-clients-table-row_clientCellStyle__J1JJe {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.dashboard-clients-table-row_title__32-QL {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: #1F1F1F;\n}\n\n.dashboard-clients-table-row_clientStatus__dzKE1 {\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 21px;\n  color: #1F1F1F;\n}\n\n.dashboard-clients-table-row_defaultItem__jXko4 {\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #1F1F1F;\n}\n\n.dashboard-clients-table-row_boldItem__D5TOo {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 21px;\n  color: #000000;\n}", "",{"version":3,"sources":["webpack://./src/packages/pages/dashboard/components/dashboard-clients-table/dashboard-clients-table-row.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".clientCellStyle {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n}\r\n\r\n.title {\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  font-size: 16px;\r\n  line-height: 24px;\r\n  color: #1F1F1F;\r\n}\r\n\r\n.clientStatus {\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-size: 14px;\r\n  line-height: 21px;\r\n  color: #1F1F1F;\r\n}\r\n\r\n.defaultItem {\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-size: 16px;\r\n  line-height: 24px;\r\n  color: #1F1F1F;\r\n}\r\n\r\n.boldItem {\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 14px;\r\n  line-height: 21px;\r\n  color: #000000;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientCellStyle": "dashboard-clients-table-row_clientCellStyle__J1JJe",
	"title": "dashboard-clients-table-row_title__32-QL",
	"clientStatus": "dashboard-clients-table-row_clientStatus__dzKE1",
	"defaultItem": "dashboard-clients-table-row_defaultItem__jXko4",
	"boldItem": "dashboard-clients-table-row_boldItem__D5TOo"
};
export default ___CSS_LOADER_EXPORT___;
