import {STORAGE_KEY_PREFIX} from "../state-management/persistor/persist-config";
import {ITokenPayload} from "../rxjs/actions/security.action";


const ACCESS_TOKEN_KEY = `${STORAGE_KEY_PREFIX}_accessToken`;
const REFRESH_TOKEN_KEY = `${STORAGE_KEY_PREFIX}_refreshToken`;

export const getJWTAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const getJWTRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const getJWTTokens = () => Promise.all([getJWTAccessToken(), getJWTRefreshToken()]);

export const setJWTAccessToken = (token: string) => localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const setJWTRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN_KEY, token);

//TODO: set expiry time for jwt tokens
//     const accessToken = {
//         value: accessToken,
//         expiry: new Date().getTime() + expiration,
//     }
//     const refreshToken = {
//         value: refreshToken,
//         expiry: new Date().getTime() + expiration,
//     }
//     Promise.all([setJWTAccessToken(JSON.stringify(accessToken)), setJWTRefreshToken(JSON.stringify(refreshToken))])

export const setJWTTokens = ({ access_token, refresh_token }: ITokenPayload) =>
    Promise.all([setJWTAccessToken(access_token), setJWTRefreshToken(refresh_token)]);

export const clearJWTTokens = () =>
    Promise.all([localStorage.removeItem(ACCESS_TOKEN_KEY), localStorage.removeItem(REFRESH_TOKEN_KEY)]);

export const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = decodeURIComponent(
        atob(base64Url)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(base64);
};