import {AxiosPromise, AxiosRequestConfig} from "axios";
import {AxiosService} from "./axios.service";
import {DefaultResponseInterceptor} from "./interceptors/default.response.interceptor";
import {TokensResponseInterceptor} from "./interceptors/tokens.response.interceptor";
import {TokensRequestInterceptor} from "./interceptors/tokens.request.interceptor";

export class HttpService extends AxiosService {

    private static _instance?: HttpService;

    protected constructor() {
        super();

        //request interceptors
        TokensRequestInterceptor(this)

        //response interceptors
        TokensResponseInterceptor(this);
        DefaultResponseInterceptor(this);
    }

    public static get instance(): HttpService {
        if (!this._instance) {
            this._instance = new HttpService();
        }

        return this._instance;
    }

    public get<T>(url: string, config: AxiosRequestConfig = {}): AxiosPromise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axiosInstance.get(url, config);

                resolve(response);
            } catch (err) {
                reject(err);
            }
        });
    }

    public post<T, D>(url: string, data = {} as D, config: AxiosRequestConfig = {}): AxiosPromise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axiosInstance.post(url, data, config);

                resolve(response);
            } catch (err) {
                reject(err);
            }
        });
    }

    //TODO: add put, delete etc.
}
