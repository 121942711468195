// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-header-row_root__UP6ki {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: stretch;\n  min-height: 60px;\n  padding-left: 16px;\n  padding-right: 16px;\n  background: #FFFFFF;\n  opacity: 0.7;\n  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);\n  border-radius: 16px 16px 0px 0px;\n  font-family: \"Montserrat\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 21px;\n  color: #36414C;\n}\n\n.table-header-row_rootDivider__l2T4t {\n  border-bottom: solid;\n  border-bottom-color: #E4EEFF;\n  border-bottom-width: 1px;\n  margin-bottom: 8px;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/table/table-header-row/table-header-row.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EAGA,mBAAA;EACA,YAAA;EACA,2CAAA;EACA,gCAAA;EAEA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;AAHF;;AAMA;EACE,oBAAA;EACA,4BAAA;EACA,wBAAA;EAEA,kBAAA;AAJF","sourcesContent":[".root {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: flex-start;\r\n  align-items: stretch;\r\n  min-height: 60px;\r\n  padding-left: 16px;\r\n  padding-right: 16px;\r\n  //padding-bottom: 8px;\r\n\r\n  background: #FFFFFF;\r\n  opacity: 0.7;\r\n  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);\r\n  border-radius: 16px 16px 0px 0px;\r\n\r\n  font-family: 'Montserrat';\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 14px;\r\n  line-height: 21px;\r\n\r\n  color: #36414C;\r\n}\r\n\r\n.rootDivider {\r\n  border-bottom: solid;\r\n  border-bottom-color: #E4EEFF;\r\n  border-bottom-width: 1px;\r\n  //padding-bottom: 16px;\r\n  margin-bottom: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "table-header-row_root__UP6ki",
	"rootDivider": "table-header-row_rootDivider__l2T4t"
};
export default ___CSS_LOADER_EXPORT___;
