// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader_loader__kiMwu {\n  border: 10px solid #f3f3f3;\n  border-top: 10px solid #3498db;\n  border-radius: 50%;\n  width: 80px;\n  height: 80px;\n  animation: loader_spin__6eNvl 1s linear infinite;\n}\n\n@keyframes loader_spin__6eNvl {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/loader/loader.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,8BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gDAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".loader {\r\n  border: 10px solid #f3f3f3;\r\n  border-top: 10px solid #3498db;\r\n  border-radius: 50%;\r\n  width: 80px;\r\n  height: 80px;\r\n  animation: spin 1s linear infinite;\r\n}\r\n\r\n@keyframes spin {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "loader_loader__kiMwu",
	"spin": "loader_spin__6eNvl"
};
export default ___CSS_LOADER_EXPORT___;
