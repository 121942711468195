// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox_wrapper__1kGAP {\n  padding-top: 16px;\n}\n.checkbox_wrapper__1kGAP input[type=checkbox] {\n  display: none;\n}\n.checkbox_wrapper__1kGAP .checkbox_group__3Q4Kl {\n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n.checkbox_wrapper__1kGAP .checkbox_checkbox__8en7M {\n  cursor: pointer;\n  height: 24px;\n  width: 24px;\n  background: #fff;\n  border: 2px #297789 solid;\n  border-radius: 4px;\n}\n.checkbox_wrapper__1kGAP .checkbox_checkboxActive__9\\+6Rs {\n  border-color: #297789;\n  background: #297789;\n}\n.checkbox_wrapper__1kGAP .checkbox_label__41Rqc {\n  font-size: 14px;\n  font-weight: 400;\n  margin-left: 8px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/checkbox/checkbox.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AAAJ;AAGE;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;AADJ;AAIE;EACE,qBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AAHJ","sourcesContent":[".wrapper {\r\n  padding-top: 16px;\r\n\r\n  input[type=\"checkbox\"] {\r\n    display: none;\r\n  }\r\n\r\n  .group {\r\n    cursor: pointer;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    width: fit-content;\r\n  }\r\n\r\n  .checkbox {\r\n    cursor: pointer;\r\n    height: 24px;\r\n    width: 24px;\r\n    background: #fff;\r\n    border: 2px #297789 solid;\r\n    border-radius: 4px;\r\n  }\r\n\r\n  .checkboxActive {\r\n    border-color: #297789;\r\n    background: #297789;\r\n  }\r\n\r\n  .label {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    margin-left: 8px;\r\n    cursor: pointer;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "checkbox_wrapper__1kGAP",
	"group": "checkbox_group__3Q4Kl",
	"checkbox": "checkbox_checkbox__8en7M",
	"checkboxActive": "checkbox_checkboxActive__9+6Rs",
	"label": "checkbox_label__41Rqc"
};
export default ___CSS_LOADER_EXPORT___;
