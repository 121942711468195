// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-row_root__I1I0J {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  min-height: 60px;\n  padding-right: 16px;\n  padding-left: 16px;\n  background: #FFFFFF;\n}\n\n.table-row_divider__-CMwR {\n  border-bottom: solid;\n  border-bottom-color: #D5DDE5;\n  border-bottom-width: 1px;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/table/table-row/table-row.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;EACA,4BAAA;EACA,wBAAA;AACF","sourcesContent":[".root {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: flex-start;\r\n  min-height: 60px;\r\n  padding-right: 16px;\r\n  padding-left: 16px;\r\n  background: #FFFFFF;\r\n}\r\n\r\n.divider {\r\n  border-bottom: solid;\r\n  border-bottom-color: #D5DDE5;\r\n  border-bottom-width: 1px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "table-row_root__I1I0J",
	"divider": "table-row_divider__-CMwR"
};
export default ___CSS_LOADER_EXPORT___;
