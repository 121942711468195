// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-pagination-styles_paginationWrapper__QxYCB {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 20px;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 21px;\n  color: #000000;\n}\n.table-pagination-styles_paginationWrapper__QxYCB .table-pagination-styles_previous__GQ1fC {\n  margin-right: 20px;\n}\n.table-pagination-styles_paginationWrapper__QxYCB .table-pagination-styles_disabled__2DzGe {\n  opacity: 50%;\n}", "",{"version":3,"sources":["webpack://./src/packages/ankored-components/table/table-pagination/table-pagination-styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,YAAA;AAAJ","sourcesContent":[".paginationWrapper {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  margin-bottom: 20px;\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 14px;\r\n  line-height: 21px;\r\n  color: #000000;\r\n\r\n  .previous {\r\n    margin-right: 20px;\r\n  }\r\n\r\n  .disabled {\r\n    opacity: 50%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationWrapper": "table-pagination-styles_paginationWrapper__QxYCB",
	"previous": "table-pagination-styles_previous__GQ1fC",
	"disabled": "table-pagination-styles_disabled__2DzGe"
};
export default ___CSS_LOADER_EXPORT___;
