import {useCallback, useState} from "react";

export const usePasswordInput = (hasClearButton: boolean) => {
    const [isShowPassword, setIsShowPassword] = useState(false);

    // const passwordIcon = isShowPassword ? OpenEyeIcon : CloseEyeIcon;
    //TODO: find out how to import styles as obj

    // const iconStyles = {
    //     ...PasswordInputStyles.icon,
    //     ...(hasClearButton && PasswordInputStyles.rightOffset)
    // };

    const inputType: 'text' | 'password' = isShowPassword ? 'text' : 'password';

    const handleToggleShowPassword = useCallback(() => setIsShowPassword(!isShowPassword), [isShowPassword]);

    return { isShowPassword, inputType, handleToggleShowPassword };
};