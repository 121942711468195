import LayoutStyles from "../ankored-layout.module.scss";
import React from "react";

export const HeaderPanel = () => {
    //TODO: create search component
    return (
        <div className={LayoutStyles.headerWrapper}>
            <span>Reports</span>
            <span>Search</span>
        </div>
    )
}