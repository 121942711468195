import {action, payload} from "ts-action";
import {IPaginationInput} from "../../types";

export const organizationListLoadAction = action('[Organizations] Load', payload<IPaginationInput>());
export const organizationListLoadFailedAction = action('[Organizations] Failed load', payload<string>());
//TODO: upd any
export const organizationListLoadSuccessAction = action('[Organizations] Successful load', payload<any>());

export const organizationAggregatedDataLoadAction = action('[Organizations] Aggregated Data Load');
export const organizationAggregatedDataLoadFailedAction = action('[Organizations] Aggregated Data Failed load', payload<string>());
export const organizationAggregatedDataLoadSuccessAction = action('[Organizations] Aggregated Data Successful load', payload<any>());

export const organizationLastUpdatesLoadAction = action('[Organizations] Last Updates Load');
export const organizationLastUpdatesLoadFailedAction = action('[Organizations] Last Updates Failed load', payload<string>());
export const organizationLastUpdatesLoadSuccessAction = action('[Organizations] Last Updates Successful load', payload<any>());