import React from "react";
import {useDispatch} from "react-redux";
import {Formik, useFormikContext} from "formik";
import LoginStyles from "../login.module.scss";
import {securitySignInAction} from "../../../rxjs";
import {ReactComponent as LogoLeftSide} from "../../../assets/Logo2.svg";
import {Link, Separator, FormikTextInput, FormikPasswordInput, FormikCheckboxInput, Button} from "../../../ankored-components";
import {initialSignInValues, ISignIn} from "../../../types";
import {loginSchema} from "../schema/login.schema";

const SignInForm = () => {
    const {handleSubmit: triggerSubmit} = useFormikContext();

    const onSubmit = () => triggerSubmit();

    return (
        <div className={LoginStyles.formWrapper}>
            <LogoLeftSide className={LoginStyles.logo}/>
            <h2>Log into your account</h2>

            <Separator/>
            <FormikTextInput name={"email"} label={"Email address"}/>
            <FormikPasswordInput name={"password"} label={"Password"}/>
            <FormikCheckboxInput name={"remember"} label={"Remember me"}/>
            <Button onClick={onSubmit} text={"Login"} className={LoginStyles.submitButton}/>
        </div>
    )
}

export const LeftSideComponent = () => {
    const dispatch = useDispatch();
    const handleSubmit = (data: ISignIn) => void dispatch(securitySignInAction(data));

    //TODO: add epic to handle password forgot business flow
    // const handleForgotPassword = void dispatch(securityForgotPasswordAction({}));
    const handleForgotPassword = void 0;

    return (
        <>
            <Formik<ISignIn> onSubmit={handleSubmit} initialValues={initialSignInValues} validationSchema={loginSchema}>
                <SignInForm/>
            </Formik>
            <div className={LoginStyles.forgotPasswordWrapper}>
                <Link onClick={handleForgotPassword} text={"Forgot your password?"}/>
            </div>
        </>
    )
}