import {HttpService} from "../../../axios/http.service";
import {ISignIn} from "../../../types";
import {catchError, concatMap, from} from "rxjs";
import {
    ISignInResponse,
    securityAccessTokenRefreshSuccessAction,
    securityAuthorizedAction,
    securitySignInFailedAction,
    securitySignInSuccessAction
} from "../..";
import {AxiosResponse} from "axios";
import {navigateAction} from "../navigation.epic";
import {parseJwt} from "../../../utils/jwt-storage.util";

export class SecurityService extends HttpService {
    protected constructor() {
        super();
    }

    //TODO: mb should add flag to allow return info or a functional for redux-obs
    public static async updateAccessToken() {
        const {security: {auth}} = this.instance.store.getState();
        const {access_token, refresh_token, remember} = auth;

        const profileId = parseJwt(access_token)?.["CurrentProfileId"];

        const searchParams = new URLSearchParams();
        searchParams.set("client_id", `${process.env.REACT_APP_IDENTITY_CLIENT_ID}`);
        searchParams.set("grant_type", "refresh_token");
        searchParams.set("refresh_token", refresh_token);
        searchParams.set("client_secret", `${process.env.REACT_APP_IDENTITY_CLIENT_SECRET}`);
        searchParams.set("scope", `${process.env.REACT_APP_IDENTITY_SCOPE}`);
        searchParams.set("CurrentProfileId", profileId);

        //TODO: upd any
        await this.instance.post<ISignInResponse, any>(`${process.env.REACT_APP_IDENTITY}/connect/token`, searchParams)
            .then(({data}) => {
                this.instance.store.dispatch(securityAccessTokenRefreshSuccessAction(data.access_token));
                return data.access_token;
            })
            .catch(error => this.instance.store.dispatch(securitySignInFailedAction()));

        // return from(this.instance.post<ISignInResponse, any>(`${process.env.REACT_APP_IDENTITY}/connect/token`, searchParams))
        //     .pipe(
        //         concatMap(({data: {access_token}}: AxiosResponse<ISignInResponse>) => {
        //             return [
        //                 EMPTY
        //                 // securityUpdateAccessTokenSuccessAction(access_token),
        //             ]
        //         }),
        //         catchError(error => [securitySignInFailedAction(error.message)])
        //     )
    }

    public static login({email, password, remember}: ISignIn) {
        const searchParams = new URLSearchParams();
        searchParams.set("client_id", `${process.env.REACT_APP_IDENTITY_CLIENT_ID}`);
        searchParams.set("grant_type", "password");
        searchParams.set("scope", `${process.env.REACT_APP_IDENTITY_SCOPE}`);
        searchParams.set("username", email);
        searchParams.set("password", password);

        //TODO: upd any
        return from(this.instance.post<ISignInResponse, any>(`${process.env.REACT_APP_IDENTITY}/connect/token`, searchParams))
        // return from(Promise.resolve(mockData))
            .pipe(
                concatMap(({data}: AxiosResponse<ISignInResponse>) => {
                // concatMap((data: ISignInResponse) => {
                    // const user = JSON.parse(new Buffer(data.access_token.split('.')[1], 'base64').toString());
                    // console.log(user);
                    return [
                        securitySignInSuccessAction({...data, remember}),
                        securityAuthorizedAction(),
                        navigateAction({
                            //TODO: create enum for all routes
                            url: "/dashboard"
                        })
                    ]
                }),
                catchError(error => [
                    securitySignInFailedAction(),
                    // renderErrorMessage(error)
                ])
            )
    }
}