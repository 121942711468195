import {createResponseInterceptor} from "../axios.service";

export const DefaultResponseInterceptor = createResponseInterceptor(
    async function (response) {
        // if (response...) {
        //
        // }

        return response;
    },
    async function (error) {
        // if ([...].includes(error?.response?.status)) {
        //     // Return unresolved promise to hang callee stack
        //     return new Promise(() => !0);
        // }

        throw error;
    }
);