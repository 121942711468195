import storage from "redux-persist/lib/storage";

export const STORAGE_KEY_PREFIX = process.env.STORAGE_KEY_PREFIX ?? "root";

//TODO: get key from .env
export const persistConfig = {
    key: STORAGE_KEY_PREFIX,
    storage,
    whitelist: ['security'],
    //TODO: add whitelist, check stateReconciler and timeout
}