// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard_dashboardWrapper__m7kJY {\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 65px);\n  padding-left: 40px;\n  padding-right: 60px;\n  padding-top: 60px;\n  overflow-y: scroll;\n  /* Hide scrollbar for IE, Edge and Firefox */\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n.dashboard_dashboardWrapper__m7kJY::-webkit-scrollbar {\n  display: none;\n}", "",{"version":3,"sources":["webpack://./src/packages/pages/dashboard/dashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EAEA,yBAAA;EAEA,kBAAA;EACA,mBAAA;EACA,iBAAA;EAEA,kBAAA;EAEA,4CAAA;EACA,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AAHF;;AAMA;EACE,aAAA;AAHF","sourcesContent":[".dashboardWrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  height: calc(100% - 65px);\r\n\r\n  padding-left: 40px;\r\n  padding-right: 60px;\r\n  padding-top: 60px;\r\n\r\n  overflow-y: scroll;\r\n\r\n  /* Hide scrollbar for IE, Edge and Firefox */\r\n  -ms-overflow-style: none;  /* IE and Edge */\r\n  scrollbar-width: none;  /* Firefox */\r\n}\r\n\r\n.dashboardWrapper::-webkit-scrollbar {\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardWrapper": "dashboard_dashboardWrapper__m7kJY"
};
export default ___CSS_LOADER_EXPORT___;
