import {catchError, concatMap, from, Observable} from "rxjs";
import {Action} from "ts-action";
import {ofType, toPayload} from "ts-action-operators";
import {
    organizationAggregatedDataLoadAction,
    organizationAggregatedDataLoadFailedAction,
    organizationAggregatedDataLoadSuccessAction,
    organizationLastUpdatesLoadAction, organizationLastUpdatesLoadFailedAction,
    organizationLastUpdatesLoadSuccessAction,
    organizationListLoadAction,
    organizationListLoadFailedAction,
    organizationListLoadSuccessAction
} from "../../actions/organizations.action";
import {HttpService} from "../../../axios/http.service";
import {AxiosResponse} from "axios";
import {combineEpics} from "redux-observable";
import {getQueryParamsFromObj} from "../../../utils/util-fns";

const organizationsListLoadEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(organizationListLoadAction),
        toPayload(),
        concatMap((queryParams) =>
            //TODO: fix types
            // @ts-ignore
            from(HttpService.instance.get<any>(`${process.env.REACT_APP_API_URL}/api/Organization/GetOrganizations${getQueryParamsFromObj(queryParams)}`))
                .pipe(
                    //TODO: fix any based on generated interfaces
                    concatMap(({data}: AxiosResponse<any>) => {
                        return [
                            organizationListLoadSuccessAction(data)
                        ]
                    }),
                    catchError(error => [
                        organizationListLoadFailedAction(error),
                        // renderErrorMessage(error)
                    ])
                )
        ));

const organizationsAggregatedDataLoadEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(organizationAggregatedDataLoadAction),
        concatMap(() =>
            //TODO: fix types
            from(HttpService.instance.get<any>(`${process.env.REACT_APP_API_URL}/api/Dashboard/GetTotals`))
                .pipe(
                    //TODO: fix any based on generated interfaces
                    concatMap(({data}: AxiosResponse<any>) => {
                        return [
                            organizationAggregatedDataLoadSuccessAction(data)
                        ]
                    }),
                    catchError(error => [
                        organizationAggregatedDataLoadFailedAction(error),
                        // renderErrorMessage(error)
                    ])
                )
        ));

const organizationsLastUpdatesLoadEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(organizationLastUpdatesLoadAction),
        concatMap(() =>
            //TODO: fix types
            from(HttpService.instance.get<any>(`${process.env.REACT_APP_API_URL}/api/Dashboard/GetLatestTotals`))
                .pipe(
                    //TODO: fix any based on generated interfaces
                    concatMap(({data}: AxiosResponse<any>) => {
                        return [
                            organizationLastUpdatesLoadSuccessAction(data)
                        ]
                    }),
                    catchError(error => [
                        organizationLastUpdatesLoadFailedAction(error),
                        // renderErrorMessage(error)
                    ])
                )
        ));

export const organizationsEpic =
    combineEpics(
        organizationsListLoadEpic,
        //TODO: fix ts error
        // @ts-ignore
        organizationsAggregatedDataLoadEpic,
        organizationsLastUpdatesLoadEpic
    );