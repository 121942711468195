import {CSSProperties} from "react";
import {isString} from "../../utils/util-fns";
import ButtonStyles from "./button.module.scss";
import classNames from "classnames";
import {OnEventFn} from "../formik/types/types";

export interface ButtonProps {
    style?: CSSProperties;
    className?: string;
    //TODO: add enum for button types
    theme?: any;
    isDisabled?: boolean;
    text?: string;
    onClick: OnEventFn<any>;
}

export const Button = ({style, theme, isDisabled, text, className, onClick}: ButtonProps) => {

    // const themeStyles = getButtonTheme(theme);

    const btnStyles = {
        // ...themeStyles?.default?.wrapper,
        // ...(isDisabled && themeStyles.disabled?.wrapper),
        ...style
    }

    const textStyles = {
        // ...themeStyles?.default?.text,
        // ...(isDisabled && themeStyles.disabled?.text),
    }

    return (
        <div
            className={classNames(ButtonStyles.wrapper, className)}
            style={btnStyles}
            onClick={onClick}
        >
            {isString(text) && (
                <span
                    style={textStyles}
                    className={ButtonStyles.text}
                >
                    {text}
                </span>
            )}
        </div>
    )
}