import {SecurityRootState} from "./states/security/security.state";
import {securityInitialState} from "./states/security/security.initial.state";
import {OrganizationsRootState} from "./states/organizations/organizations.state";
import {organizationsInitialState} from "./states/organizations/organizations.initial.state";

export interface RootState extends SecurityRootState, OrganizationsRootState {}

export const rootInitialState: RootState = {
    security: securityInitialState,
    organizations: organizationsInitialState
};
