import React, {PropsWithChildren} from 'react';
import {TableCellProps} from "../type/table-config.type";
import TableCellStyles from "./table-cell.module.scss";
import {useTableCellStyle} from "../hook/use-table-cell-style.hook";
import classNames from "classnames";

export const TableCell = ({name = "", style, className, children, numeric: initialNumeric = false}: PropsWithChildren<TableCellProps>) => {
    const {cellStyle, numeric = false, className: configClassName} = useTableCellStyle(name, initialNumeric);

    const containerStyles = {
        ...cellStyle,
        ...style
    };

    return (
        <div
            style={containerStyles}
            className={classNames(TableCellStyles.container, numeric && TableCellStyles.right, configClassName, className)}
        >
            {children}
        </div>
    );
};
