import React, {useEffect} from "react";
import {ReactComponent as Clients} from "../../../../assets/dashboard/Clients.svg";
import {ReactComponent as Users} from "../../../../assets/dashboard/Users.svg";
import {ReactComponent as Profiles} from "../../../../assets/dashboard/Profiles.svg";
import {ReactComponent as Conditions} from "../../../../assets/dashboard/Conditions.svg";
import {ReactComponent as Teams} from "../../../../assets/dashboard/Teams.svg";
import {ReactComponent as Admins} from "../../../../assets/dashboard/Admins.svg";
import {ReactComponent as Incidents} from "../../../../assets/dashboard/Incidents.svg";
import {ReactComponent as Requirements} from "../../../../assets/dashboard/Requirements.svg";
import {ReactComponent as APT} from "../../../../assets/dashboard/APT.svg";
import {ReactComponent as BGC} from "../../../../assets/dashboard/BGC.svg";
import AggregatedDataStyles from "./aggregated-data.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {OrganizationsRootState} from "../../../../state-management/states/organizations/organizations.state";
import {IItemState} from "../../../../types/item-state";
import {organizationAggregatedDataLoadAction} from "../../../../rxjs/actions/organizations.action";

export const AggregatedData = () => {
    //TODO: upd any based on generated interfaces
    const {data: aggregatedData} =
        useSelector<OrganizationsRootState, IItemState<any, any>>(({organizations}) => organizations.aggregatedData
        );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(organizationAggregatedDataLoadAction());
    }, []);

    return (
        <div className={AggregatedDataStyles.aggregatedDataWrapper}>

            <div className={AggregatedDataStyles.item}>
                <Clients/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalClients}</span>
                <span className={AggregatedDataStyles.label}>Total Clients</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <Users/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalUsers}</span>
                <span className={AggregatedDataStyles.label}>Total Users</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <Profiles/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalProfiles}</span>
                <span className={AggregatedDataStyles.label}>Total Profiles</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <Admins/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalAdmins}</span>
                <span className={AggregatedDataStyles.label}>Total Admins</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <Teams/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalTeams}</span>
                <span className={AggregatedDataStyles.label}>Total Teams</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <Incidents/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalIncidents}</span>
                <span className={AggregatedDataStyles.label}>Total Incidents</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <Conditions/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalConditions}</span>
                <span className={AggregatedDataStyles.label}>Total Conditions</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <Requirements/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalRequirements}</span>
                <span className={AggregatedDataStyles.label}>Total Requirements</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <APT/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalApt}</span>
                <span className={AggregatedDataStyles.label}>Total APT</span>
            </div>

            <div className={AggregatedDataStyles.item}>
                <BGC/>
                <span className={AggregatedDataStyles.amount}>{aggregatedData.totalBcc}</span>
                <span className={AggregatedDataStyles.label}>Total BGCs</span>
            </div>
        </div>
    )
}