import React, {PropsWithChildren} from 'react';
import {Provider} from 'react-redux';
import {Store} from "redux";
import {PersistGate} from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist/es/types';

interface Props<T> {
    persistor: Persistor;
    store: Store<T>;
}

export const Root = <T extends {}>({store, persistor, children}: PropsWithChildren<Props<T>>) => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};
