import {rootReducer} from './root.reducer';
import {rootInitialState, RootState} from './root.state';
import {configureStore} from "./store/configure-store";
import {persistConfig} from "./persistor/persist-config";
import {securityEpic} from "../rxjs/epics/security/security.epic";
import {navigationEpic} from "../rxjs/epics/navigation.epic";
import {organizationsEpic} from "../rxjs/epics/organizations/organizations.epic";

export default configureStore<RootState>(
    rootInitialState,
    rootReducer,
    persistConfig,
    securityEpic,
    navigationEpic,
    organizationsEpic
);
