import {on, On} from "ts-action";
import {OrganizationsState} from "../../states/organizations/organizations.state";
import {
    organizationAggregatedDataLoadAction,
    organizationAggregatedDataLoadFailedAction,
    organizationAggregatedDataLoadSuccessAction,
    organizationLastUpdatesLoadAction, organizationLastUpdatesLoadFailedAction,
    organizationLastUpdatesLoadSuccessAction,
    organizationListLoadAction,
    organizationListLoadFailedAction,
    organizationListLoadSuccessAction
} from "../../../rxjs/actions/organizations.action";
import {listInitialState} from "../../initial-states/list-initial-state";
import {itemInitialState} from "../../initial-states/item-initial-state";

export const organizationsListLoadReducer: Array<On<OrganizationsState>> = [
    on(organizationListLoadAction, (state, {payload: queryVariables}) => ({
        ...state,
        list: {
            ...state["list"],
            isLoading: true,
            queryVariables
        }
    })),
    on(organizationListLoadSuccessAction, (state, {payload: {data, details}}) => ({
        ...state,
        list: {
            ...state["list"],
            data,
            pagination: details,
            isLoading: false,
            error: ""
        }
    })),
    on(organizationListLoadFailedAction, (state, {payload: error}) => ({
        ...state,
        list: {
            ...listInitialState,
            error
        }
    }))
];

export const organizationsAggregatedDataLoadReducer: Array<On<OrganizationsState>> = [
    on(organizationAggregatedDataLoadAction, state => ({
        ...state,
        aggregatedData: {
            ...state["aggregatedData"],
            isLoading: true
        }
    })),
    on(organizationAggregatedDataLoadSuccessAction, (state, {payload: {data, details}}) => ({
        ...state,
        aggregatedData: {
            ...state["aggregatedData"],
            data,
            isLoading: false,
            error: ""
        }
    })),
    on(organizationAggregatedDataLoadFailedAction, (state, {payload: error}) => ({
        ...state,
        aggregatedData: {
            ...itemInitialState,
            error
        }
    }))
];

export const organizationsLastUpdatesLoadReducer: Array<On<OrganizationsState>> = [
    on(organizationLastUpdatesLoadAction, state => ({
        ...state,
        lastUpdates: {
            ...state["lastUpdates"],
            isLoading: true
        }
    })),
    on(organizationLastUpdatesLoadSuccessAction, (state, {payload: {data, details}}) => ({
        ...state,
        lastUpdates: {
            ...state["lastUpdates"],
            data,
            isLoading: false,
            error: ""
        }
    })),
    on(organizationLastUpdatesLoadFailedAction, (state, {payload: error}) => ({
        ...state,
        lastUpdates: {
            ...itemInitialState,
            error
        }
    }))
];