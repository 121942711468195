import {object, string} from "yup";

export const loginSchema = object().shape({
    email: string()
        .required("Email is required")
        .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, {
            message: "Please enter a valid email",
            excludeEmptyString: true,
        }),
    password: string()
        .required("Password is required")
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, {
            message: "Password not valid",
            excludeEmptyString: true
        })
})