import React, {PropsWithChildren} from 'react';
import {useTableCellStyle} from "../hook/use-table-cell-style.hook";
import {TableCellProps} from "../type/table-config.type";
import TableHeaderCellStyles from "./table-header-cell.module.scss"
import classNames from "classnames";

export const TableHeaderCell = ({name = "", style, textStyle, children}: PropsWithChildren<TableCellProps>) => {
    const { cellStyle, className, numeric = false } = useTableCellStyle(name);

    const containerStyles = {
        ...cellStyle,
        ...style
    };

    return (
        <div
            style={containerStyles}
            className={classNames(TableHeaderCellStyles.container, numeric && TableHeaderCellStyles.right, className)}
        >
            <span
                style={textStyle}
                className={TableHeaderCellStyles.text}
            >
                {children}
            </span>
        </div>
    );
};
