import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {Store} from "redux";
import redux from "../state-management/ankored-configure-store";
import {RootState} from "../state-management/root.state";

export abstract class AxiosService {
    public readonly axiosInstance: AxiosInstance = axios.create();

    public readonly httpCache: Map<string, AxiosResponse> = new Map();

    public readonly store: Store<RootState> = redux.store;

    protected readonly requestInterceptors: Array<{
        onResolve: (config: AxiosRequestConfig) => any;
        onReject?: <T>(error: T) => Promise<T>;
    }> = [];

    protected readonly responseInterceptors: Array<{
        onResolve: (response: AxiosResponse) => Promise<AxiosResponse>;
        onReject?: (error: AxiosError) => Promise<AxiosError> | AxiosError;
    }> = [];

    protected constructor() {
        this._applyRequestInterceptors();
        this._applyResponseInterceptors();
    }

    public addRequestInterceptor<T>(
        onRequest: (this: AxiosService, config: AxiosRequestConfig) => any,
        onError?: (this: AxiosService, error: T) => Promise<T>
    ) {
        this.requestInterceptors.push({
            onResolve: onRequest.bind(this),
            //TODO: fix type
            //@ts-ignore
            onReject: onError?.bind(this),
        });

        return this;
    }

    /**
     * Method for attaching response interceptor to axios
     * @param onResolve
     * @param onReject
     */
    public addResponseInterceptor(
        onResolve: (this: AxiosService, response: AxiosResponse) => Promise<AxiosResponse>,
        onReject?: (this: AxiosService, e: AxiosError) => Promise<AxiosError>
    ) {
        this.responseInterceptors.push({
            onResolve: onResolve.bind(this),
            onReject: onReject?.bind(this),
        });

        return this;
    }

    private _applyRequestInterceptors(): void {
        // Apply request interceptors
        this.axiosInstance.interceptors.request.use(
            (requestConfig) => {
                requestConfig = this.requestInterceptors.reduce((config, interceptor) => {
                    return interceptor.onResolve(config);
                }, requestConfig);

                return requestConfig;
            },
            async (error) => {
                for (const interceptor of this.requestInterceptors) {
                    error = await (interceptor.onReject?.(error) ?? error);
                }

                return error;
            }
        );
    }

    private _applyResponseInterceptors(): void {
        const targets = [axios, this.axiosInstance];
        // Apply request interceptors
        targets.forEach((target) =>
            target.interceptors.response.use(
                async (axiosResponse) => {
                    for (const interceptor of this.responseInterceptors) {
                        axiosResponse = await interceptor.onResolve(axiosResponse);
                    }

                    return axiosResponse;
                },
                async (error) => {
                    for (const interceptor of this.responseInterceptors) {
                        error = await (interceptor.onReject?.(error) ?? error);
                    }

                    return error;
                }
            )
        );
    }
}

type TAddRequestInterceptor = Parameters<AxiosService["addRequestInterceptor"]>;
type TAddResponseInterceptor = Parameters<AxiosService["addResponseInterceptor"]>;

export function createRequestInterceptor(onRequest: TAddRequestInterceptor[0], onError?: TAddRequestInterceptor[1]) {
    return (service: AxiosService) => service.addRequestInterceptor(onRequest, onError);
}

export function createResponseInterceptor(
    onResponse: TAddResponseInterceptor[0],
    onError?: TAddResponseInterceptor[1]
) {
    return (service: AxiosService) => service.addResponseInterceptor(onResponse, onError);
}
