import {applyMiddleware, createStore, Reducer, Store} from 'redux';
import {combineEpics, createEpicMiddleware, Epic} from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import {PersistConfig, persistReducer, persistStore} from 'redux-persist';


import {rootStateReducer} from "../reducers/root-state.reducer";
import {Persistor} from "redux-persist/es/types";

//TODO: move to shared interfaces
interface StoreType<T> {
    persistor: Persistor;
    store: Store<T>;
}

export const configureStore = <T>(
    initialState: T,
    appReducer: Reducer<T>,
    persistConfig: PersistConfig<T>,
    ...epics: Epic[]
): StoreType<T> => {
    const persistedReducer = persistReducer(persistConfig, rootStateReducer(appReducer));
    const epicMiddleware = createEpicMiddleware();

    const enhancers = applyMiddleware(epicMiddleware);

    // @ts-ignore
    const store = createStore(persistedReducer, initialState, composeWithDevTools(enhancers));
    const persistor = persistStore(store);

    epicMiddleware.run(combineEpics(...epics));

    return { store, persistor };
};
