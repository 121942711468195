import React from 'react';
import {useSelector} from "react-redux";
import {SecurityRootState} from "../../../state-management/states/security/security.state";
import {Navigate, Route, Routes} from "react-router-dom";
import {SignInScreen} from "../../../pages/login/sign-in";
import {DashboardScreen} from "../../../pages/dashboard/dashboard";
import {AnkoredLayout} from "../ankored-layout/ankored-layout";

export const AnkoredNavigator = () => {
    const [isAuthorized] = useSelector<SecurityRootState, [boolean]>(({security}) => [security.isLoggedIn]);

    return (
        <AnkoredLayout isAuthorized={isAuthorized}>
            <Routes>
                {
                    !isAuthorized && (
                        <>
                            <Route path="/sign-in" element={<SignInScreen/>}/>
                            <Route path="/*" element={<Navigate to="/sign-in" replace/>}/>
                        </>
                    )
                }
                {
                    isAuthorized && (
                        <>
                            <Route path="/dashboard" element={<DashboardScreen/>}/>
                            <Route path="/*" element={<Navigate to="/dashboard" replace/>}/>
                        </>
                    )}
            </Routes>
        </AnkoredLayout>
    );
};
