import React from 'react';
import {AnkoredNavigator} from "../ankored-navigator/ankored-navigator";
import {HttpServiceProvider} from "../../../axios/http.provider";

export const AnkoredRoot = () => {

    //TODO: place for additional providers etc.
    return (
        <HttpServiceProvider>
            <AnkoredNavigator/>
        </HttpServiceProvider>
    )
};