import {ITableCellStyleConfig} from "..";
import {CSSProperties} from "react";
import classNames from "classnames";
import TableStyles from "../table-styles.module.scss"

export const getTableCellClassName = ({first, last, className}: ITableCellStyleConfig): string =>
    classNames(className, first === true && TableStyles.firstCell, last === true && TableStyles.lastCell)


export const getRowStyles = (index: number): CSSProperties => ({
    // ...(index % 2 === 0 ? TableStyles.rowOdd : TableStyles.rowEven),
})