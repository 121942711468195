export interface ISignIn {
    email: string,
    password: string,
    remember: boolean
}

export const initialSignInValues: ISignIn = {
    email: "",
    password: "",
    remember: false
}