import {isDefined} from "../../utils/util-fns";
import React, {CSSProperties} from "react";
import LinkStyles from "./link.module.scss";

interface Props {
    onClick?: (...args: any[]) => void,
    href?: string,
    text: string,
    style?: CSSProperties
}

export const Link = ({onClick, href, text, style}: Props) => {
    if(isDefined(onClick)){
        return <span onClick={onClick} className={LinkStyles.link} style={style}>{text}</span>
    } else {
        return <a href={href} className={LinkStyles.link} style={style}>{text}</a>
    }
}