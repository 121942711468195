import {Action, action, payload} from "ts-action";
import {concatMap, EMPTY, Observable} from "rxjs";
import {ofType, toPayload} from "ts-action-operators";
import {combineEpics} from "redux-observable";
import customHistory from "../../ankored-components/core/history";

//TODO: use createNavigationActions instead; export temporary allowed
export const navigateAction = action('NAVIGATE', payload<any>());

//TODO: use func for security routes, and others
export const createNavigationActions = <T>() => ({
    navigateAction: <RouteName extends keyof T>(...navigationArgs: any) =>
        action('NAVIGATE', payload<any>())(navigationArgs),
});

//TODO: create enum for all routes
const specificRoutesActions = (url: string) => {
    switch (url) {
        default :
            return EMPTY
    }
}

const navigateEpic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(navigateAction),
        toPayload(),
        concatMap(navigationArgs => {
            customHistory.push(navigationArgs.url);

            return specificRoutesActions(navigationArgs.url);
        })
    );

export const navigationEpic = combineEpics(navigateEpic);